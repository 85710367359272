import * as React from "react";
import Layout from "../../components/layout";
import { Modal, Button, Form } from 'react-bootstrap';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';
export default function Index() {
  return <div className="contact-page">
        <div className="contact-title">Contact</div>
        <div className="contact-cards">
            <div className="row card-row">
              
              <div className="col-xl-3 col-sm-12 card" data-aos="fade-up">
              <FontAwesomeIcon className="icon" icon={faPhone} size="3x" />
                  <span className="title">
                  CALL US
                  </span>
                  <span className="sub-title">
                  <a href="tel:8826617732">+91-8826617732</a>
                  </span>
              </div>
              <div className="col-xl-3 col-sm-12 card" data-aos="fade-up">
              <FontAwesomeIcon className="icon" icon={faMapMarkerAlt} size="3x" />
                  <span className="title">
                    OFFICE ADDRESS
                  </span>
                  <span className="sub-title">
                  Shop No.7, Janpath Lane, Connaught Place, <br></br>New Delhi – 110001
                  </span>
              </div>
              <div className="col-xl-3 col-sm-12 card" data-aos="fade-up">
              <FontAwesomeIcon className="icon" icon={faEnvelope} size="3x" />
                  <span className="title">
                  WRITE TO US
                  </span>
                  <span className="sub-title">
                  <a href="mailto:mailus@theskillhunters.com">mailus@theskillhunters.com</a>
                  </span>
              </div>
            </div>
        </div>
      <div className="contact-page">
        <Form action="http://theskillhunters.com/send-email.php"  onsubmit="return validateForm();" method="post">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Your Name</Form.Label>
                <Form.Control name ="name" type="text" placeholder="Your Name" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control name ="phone" type="text" placeholder="Phone Number" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control name ="email" type="email" placeholder="Enter email" />
              </Form.Group>

              <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label>Subject</Form.Label>
                <Form.Control name ="subject" type="text" placeholder="Subject" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Describe your requirements</Form.Label>
                <Form.Control name ="description" as="textarea" rows={3} />
              </Form.Group>
              
              {/* <Button variant="primary" type="submit" onClick={handleContactClose}> */}
              <Button type='submit' variant="primary" onClick={()=>console.log("Contact Submitted")}>
                
                Submit
              </Button>
            </Form>
      </div>
  </div>
}