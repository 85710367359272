import React from "react";
import { Helmet } from "react-helmet"
import AppHeader from "../appHeader";
import AppFooter from "../footer";

export default function Layout({ children }) {
    const pageStyles = {
        color: "#232129",
        padding: 96,
        fontFamily: "-apple-system, Roboto, sans-serif, serif",
      }
      
      const codeStyles = {
        color: "#8A6534",
        padding: 4,
        backgroundColor: "#FFF4DB",
        fontSize: "1.25rem",
        borderRadius: 4,
      }
  return (
    <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>The Skill Hunters</title>
        </Helmet>
      <AppHeader></AppHeader>
      {children}
        <AppFooter></AppFooter>
        <main style={pageStyles}>
      <title>Home Page</title>
      <p>
        {/* <code style={codeStyles}>src/pages/index.js</code>  */}
      </p>
      </main>
    </div>

  )
}
