import * as React from "react";
import './style.scss';
import UserHeader from "../userHeader";
import whatsapp from './../../images/whatsapp.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isMobile } from "../../utils";
import {
  faEnvelope,
  faPhone,
  faWhatsapp
} from '@fortawesome/free-solid-svg-icons';
export default function AppHeader() {
  console.log("ooooo", isMobile())
  return <div className="hdr">
    <div className="app-header-items">
      <span className="item"><FontAwesomeIcon className="icon" icon={faEnvelope} size="1x" /><a href="mailto:mailus@theskillhunters.com">mailus@theskillhunters.com</a></span><br className="n-line"></br>
      {/* <span className="item"><FontAwesomeIcon className="icon" icon={faEnvelope} size="1x" /><a href="mailto:jobs@theskillhunters.com">jobs@theskillhunters.com</a></span> */}
      <span className="item"><a href="https://wa.me/918826617732"><img src={whatsapp} className="icon" /></a><a href="tel:8826617732"><FontAwesomeIcon className="icon" icon={faPhone} size="1x" />8826617732</a></span>
      {/* <span className="item"><img src={whatsapp} className="icon" /><a href="https://wa.me/8826617732">8826617732</a></span> */}
    </div>
    <UserHeader isMobile={isMobile()}></UserHeader>
  </div>

}