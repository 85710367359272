import * as React from "react";
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMapMarkerAlt,
  faPhone,
  faComment
} from '@fortawesome/free-solid-svg-icons';
export default function AppFooter() {
  return (
    <div className="m-mob5">
    <div className="divider div-transparent"></div>
      <div className="footer"> 
        <div className="row">
          <div className="col-md-4 col-sm-11 items">
              All rights are reserved &copy; The Skill Hunters 2021
          </div>
          <div className="col-md-4 col-sm-11 items">
              <b>ABOUT THE COMPANY</b><br></br>
              Connect with us for more information.
          </div>
          <div className="col-md-4 col-sm-11 items">
          <FontAwesomeIcon className="icon" icon={faMapMarkerAlt} size="1x" /><span className="contact-items">Shop No.7, Janpath Lane, Connaught Place, New Delhi – 110001</span><br></br>
          <FontAwesomeIcon className="icon" icon={faPhone} size="1x" /><span className="contact-items">8826617732</span><br></br>
          </div>
      </div>
    </div>
  </div>
  )
}