import * as React from "react";
import './style.scss';
import 'aos/dist/aos.css';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import whatsappBig from './../../images/whatsapp-big.png';
import logo from './../../images/logo_w.png';
import {
  faBars,
  faTimes,
  faCommentDots
} from '@fortawesome/free-solid-svg-icons';

export default function UserHeader(props) {
  const onClick = (item) =>{
    // if(item === '#home'){
    //   AOS.refresh()
    // }
    console.log("uuuuuuu", window.location.pathname)
    if(window.location.pathname!=='/'){
      window.location.pathname = '/'
    }
    setTimeout(() => {
      const id = item.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    // handleClose()
    }, 0);
  }
  const onHamburgerClick = () => {
    console.log("On Hamburger Click");
    handleShow()
  }

  const [show, setShow] = React.useState(false);
  const [showContact, setShowContact] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleContactClose = () => setShowContact(false);
  const handleContactShow = () => setShowContact(true);

  // console.log("ttttttt", props.isMobile)
  return <div className="usr-hdr">
    <div className="logo">
      <img src={logo}></img>
      {/* <p>Skill Hunters Consultancy</p> */}
    </div>
    <div className="item web">
      <p><a onClick={()=>onClick("#home")}>Home</a></p>
      <p><a onClick={()=>onClick("#about")}>About</a></p>
      <p><a onClick={()=>onClick("#services")}>Services</a></p>
      <p><a onClick={()=>onClick("#contact")}>Contact</a></p>
    </div>
    <div className="item mobile">
      <p><a onClick={()=>onHamburgerClick()} data-toggle="modal" data-target="#exampleModal" className="mobile-menu-icon">
        <FontAwesomeIcon className="icon-ham" icon={faBars} size="2x" />
        </a>
      </p>
    </div>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
          <a className="mobile-menu-icon" onClick={handleClose}><FontAwesomeIcon className="icon" icon={faTimes} size="2x" /></a>
        </Modal.Header>
        <Modal.Body>
          <div className="mobile-menu">
            <p><a onClick={()=>onClick("#home")}>Home</a></p>
            <p><a onClick={()=>onClick("#about")}>About</a></p>
            <p><a onClick={()=>onClick("#services")}>Services</a></p>
            <p><a onClick={()=>onClick("#contact")}>Contact</a></p>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showContact} onHide={handleContactClose}>
        <Modal.Header className="contact">
          <Modal.Title></Modal.Title>
          <a className="contact-modal-icon" onClick={handleContactClose}><FontAwesomeIcon className="icon" icon={faTimes} size="2x" /></a>
        </Modal.Header>
        <Modal.Body>
        <Form action="http://theskillhunters.com/send-email.php"  onsubmit="return validateForm();" method="post">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Your Name</Form.Label>
                <Form.Control name ="name" type="text" placeholder="Your Name" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control name ="phone" type="text" placeholder="Phone Number" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control name ="email" type="email" placeholder="Enter email" />
              </Form.Group>

              <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label>Subject</Form.Label>
                <Form.Control name ="subject" type="text" placeholder="Subject" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Describe your requirements</Form.Label>
                <Form.Control name ="description" as="textarea" rows={3} />
              </Form.Group>
              
              {/* <Button variant="primary" type="submit" onClick={handleContactClose}> */}
              <Button type='submit' variant="primary" onClick={()=>console.log("Contact Submitted")}>
                
                Submit
              </Button>
            </Form>
        </Modal.Body>
      </Modal>

      <a class="fixedButtonWhatsapp" href="https://wa.me/918826617732">
        <div class="roundedFixedBtn"><img src={whatsappBig} className="icon-side-fixed" /></div>
      </a>
      <a class="fixedButton" onClick={handleContactShow}>
        <div class="roundedFixedBtn"><FontAwesomeIcon className="icon" icon={faCommentDots} size="1x" /></div>
      </a>
  </div>
}